<template>
    <div>
        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="community.name">{{community.name}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>Community name</h3>
           
            <md-button class="md-raised md-accent" @click="destroy">Delete community</md-button>
            <md-button class="md-primary md-raised" @click="fireUpdate">Save</md-button>
        </md-toolbar>
        <div v-if="isBusy">
            <md-progress-bar md-mode="query" ></md-progress-bar> 
        </div>
        <div v-else>
            <div class="main-inner-wrapper">
                <CRow :gutters="true">
                    <CCol sm="12" lg="12">
                        <md-card>
                            <md-card-header>
                                <div class="md-title">General info</div>
                            </md-card-header>
    
                            <md-card-content>
                                <CRow>
                                    <CCol sm="6">
                                        <md-field :class="getValidationClass('name')">
                                            <label>Name</label>
                                            <md-input v-model="community.name"></md-input>
                                            <span class="md-error" v-if="!$v.community.name.required">Name is required</span>
                                        </md-field>
                                    </CCol>
                                   
                                </CRow>
                                
                            </md-card-content>
                        
                        </md-card>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="12" lg="12" >         
                        <CRow class="mt-4">
                            <CCol sm="12" lg="12" >
                                <md-card>
                                    <md-card-header>
                                        <div class="md-title">Users list</div>
                                    </md-card-header>
            
                                    <md-card-content>
                                        <md-table v-if="selectedUsers.length > 0" @md-selected="onSelectToSave" v-model="selectedUsers">
                                            <md-table-toolbar>
                                                <div class="md-toolbar-section-start">
                                                
                                                </div>

                                                <div class="md-toolbar-section-end">
                                                    <md-button @click="openDialog" class="md-primary md-raised mr-0">Add users</md-button>
                                                </div>
                                            </md-table-toolbar>
                                            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                                                <div class="md-toolbar-section-start">Remove {{ count }} {{ count === 1 ? 'user' : 'users' }} from list</div>
    
                                                <div class="md-toolbar-section-end">
                                                    <md-button class="md-raised md-accent" @click="removeUsers()">
                                                       Remove
                                                    </md-button>
                                                </div>
                                            </md-table-toolbar>
                                           
                                    
                                            <md-table-row 
                                                slot="md-table-row" slot-scope="{ item }"
                                                class="white-space-no-wrap"
                                                md-selectable="multiple" 
                                                md-auto-select
                                                
                                                >
                                                <md-table-cell md-label="Email">
                                                    {{item.email}}
                                                    
                                                </md-table-cell>
                                                <md-table-cell md-label="Phones">
                                                    <ul class="table-inner-list">
                                                        <li v-for="(phone, index) in item.phones" v-bind:key="phone + index + 'linked'">
                                                            {{ phone }}
                                                        </li>
                                                    </ul>
                                                </md-table-cell>
                                                <md-table-cell md-label="Areas">
                                                    <ul class="table-inner-list">
                                                        <li v-for="(area,index) in item.areas" v-bind:key="area.formatted_address + index + 'linked'">
                                                            {{ area.formatted_address }}
                                                        </li>
                                                    </ul>
                                                </md-table-cell>
                                                <md-table-cell md-label="Fleet" class="text-left">
                                                    <ul class="pl-0 mb-0">
                                                        <li v-for="(vehicle,index) in item.filteredFleet" v-bind:key="'selected-' + vehicle.name + index">
                                                            {{ vehicle.mainInfo.brand }} {{ vehicle.mainInfo.carModel }} {{ vehicle.mainInfo.year }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                                                        </li>
                                                    </ul>
                                                </md-table-cell>
                                                <md-table-cell md-label="Role">{{item.role}}</md-table-cell>
                                                <md-table-cell md-label="Code">{{item.code}}</md-table-cell>
                                                
                                                
                                            </md-table-row>
                                        </md-table>
                                        <md-empty-state
                                             v-else
                                            class="md-primary"
                                            md-icon="person_off"
                                            md-label="No users selected"
                                            >
                                            <md-button class="md-primary md-raised" @click="openDialog">Add users</md-button>
                                        </md-empty-state>
    
                                    </md-card-content>
                                
                                </md-card>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </div>
        </div>

        <md-dialog
                :style="[dialogDimensions]"
                :md-active.sync="dialog.active"
                @md-closed="dialogClosed()"
            >
            <md-dialog-title class="up-text-primary md-headline mb-2">Add </md-dialog-title>
            <md-dialog-content>
                <CRow>

                    <CCol sm="12" lg="12" class="mb-3">
                        <md-field >
                            <label for="role">Role</label>
                            <md-select v-model="community.role" name="role" id="role" @md-selected="roleSelected()" disabled>
                                <md-option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.text }}</md-option>
                                
                            </md-select>
                            
                        </md-field>
                        <md-field  v-if="community.role == 'driver' ">
                            <md-select v-model="community.vehicleType" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" disabled>
                                <md-option v-for="vehicle in baseVehicleTypes" :value="vehicle.id" v-bind:key="vehicle.id">{{vehicle.type ? vehicle.type : vehicle.name }}</md-option>
                            </md-select>
                        </md-field>
                        <md-table v-if="filteredLinkedUsers.length > 0 && community.role" @md-selected="onSelect" v-model="searched">
                            <md-table-toolbar>
                                <md-field md-clearable class="md-toolbar-section-start">
                                    <md-input placeholder="Search by mail, phone, area, code ..." v-model="search" @input="searchOnTable" />
                                </md-field>
                            </md-table-toolbar>
                            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                                <div class="md-toolbar-section-start">Add {{ count }} {{ count === 1 ? 'user' : 'users' }} to list</div>

                                <div class="md-toolbar-section-end">
                                    <md-button class="md-raised md-primary" @click="addUsers()">
                                        Add
                                    </md-button>
                                </div>
                            </md-table-toolbar>
                            <md-table-row class="white-space-no-wrap" slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" md-auto-select v-if="searched.length > 0">
                                <md-table-cell md-label="Email">
                                    {{item.email}}
                                    
                                </md-table-cell>
                                <md-table-cell md-label="Phones">
                                    <ul class="table-inner-list">
                                        <li v-for="(phone, index) in item.phones" v-bind:key="phone + index + 'linked'">
                                            {{ phone }}
                                        </li>
                                    </ul>
                                </md-table-cell>
                                <md-table-cell  md-label="Areas">
                                    <ul class="table-inner-list">
                                        <li v-for="(area,index) in item.areas" v-bind:key="area.formatted_address + index + 'linked'">
                                            {{ area.formatted_address }}
                                        </li>
                                    </ul>
                                </md-table-cell>
                                <md-table-cell md-label="Fleet" class="text-left">
                                    <ul class="pl-0 mb-0">
                                        <li v-for="(vehicle,index) in item.filteredFleet" v-bind:key="vehicle.name + index">
                                            {{ vehicle.mainInfo.brand }} {{ vehicle.mainInfo.carModel }} {{ vehicle.mainInfo.year }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                
                                        </li>
                                    </ul>
                                </md-table-cell>
                                <md-table-cell md-label="Role">{{item.role}}</md-table-cell>
                                <md-table-cell md-label="Code">{{item.code}}</md-table-cell>
                                
                                
                                
                            </md-table-row>
                        </md-table>
                        <md-empty-state
                            v-else-if="!community.role"
                            class="md-primary"
                            md-icon="badge"
                            md-label="Select user role"
                            >
                        </md-empty-state>
                        
                        <md-empty-state
                            v-else-if="filteredLinkedUsers.length === 0 && searched.length === 0"
                            class="md-primary"
                            md-icon="badge"
                            md-label="No possible users to add"
                            md-description="Search for new partners"
                            >
                        </md-empty-state>

                        <md-empty-state
                            v-if="filteredLinkedUsers.length > 0 && searched.length === 0"
                            class="md-primary"
                            md-icon="search"
                            :md-label="'No possible matches for ' + search "
                            >
                        </md-empty-state>
                            
                    </CCol>
                </CRow>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button @click="dialog.active = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
        
        
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import {
    required,
    requiredIf,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
import axios from "./../../helpers/axios";

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    //return items.filter(item => toLower(item.readableId).includes(toLower(term)))
    return items.filter(item => {
        
        let phoneMatches = []
        if(item.phones.length > 0) {
            for (let i = 0; i < item.phones.length; i++) {
                if(toLower(item.phones[i]).includes(toLower(term))) {
                    phoneMatches.push(true)
                }
                
            }
        }

        let areasMatches = []
        if(item.areas.length > 0) {
            for (let i = 0; i < item.areas.length; i++) {
                if(toLower(item.areas[i].formatted_address).includes(toLower(term))) {
                    areasMatches.push(true)
                }
                
            }
        }

        let fleetMatches = []
        if(item.fleet.length > 0) {
            for (let i = 0; i < item.fleet.length; i++) {
                if(toLower(item.fleet[i].name).includes(toLower(term)) || toLower(item.fleet[i].vehicleType.name).includes(toLower(term))) {
                    fleetMatches.push(true)
                }
                
            }
        }

       return  toLower(item.email).includes(toLower(term)) || 
                phoneMatches.includes(true) ||
                areasMatches.includes(true) ||
                fleetMatches.includes(true) ||
                toLower(item.code).includes(toLower(term)) 
             
      
    })
  }

  return items
}

export default {
    name: 'Community',
    mixins: [validationMixin],
    components: {
        VueTimepicker,
    },
    data() {
        return {
            isBusy: true,
            community: {
                name: null,
                role: null
            }, 
            roles: [
                
                {
                    name: 'company-admin',
                    text: 'Car and limo service company'
                },
                {
                    name: 'driver',
                    text: 'Owner operator driver'
                },
                {
                    name: 'dispatcher',
                    text: 'Dispatcher'
                },
                {
                    name: 'affiliate',
                    text: 'Affiliate'
                },
                
            ],
            companyDrivers: [],
            linkedUsers: [],
            filteredLinkedUsers: [],
            initialFiltered : [],
            selectedUsers: [],
            currentlySelectedUsers: [] ,
            toRemoveUsers: [],
            collectionGroupRef: null,
            dialog: {
                active: false
            },
            search: null,
            searched:[],
            baseVehicleTypes: []
           
        }
    },
    
    methods: {
        async getVehicleTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.baseVehicleTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.baseVehicleTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        myEventHandler(e) {
            this.$store.commit('centerDialog',window.innerWidth) 
        },
        searchOnTable () {
            this.searched = searchByName(this.filteredLinkedUsers, this.search)
        },
        dialogClosed() {

        },
        openDialog() {
            
            this.dialog.active = true
        },
        destroy() {
            let th = this;
            let communitiesCollectionRef
           

            communitiesCollectionRef = firebase.firestore()
                        .collection('users')
                        .doc(this.user.data.uid)
                        .collection('communities')


            communitiesCollectionRef.doc(this.$route.params.id).delete()
            .then(function(){
                console.log('Deleted');
                th.$router.push({path: `/app/communities`})
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });;
        },
        async saveUsers(ref) {
            console.log('add users')
            const promises = this.selectedUsers.map(async (user, index) => {
                console.log(user)
                if(!user.existant) {

                    return ref.add(user)
                }
            })

            return Promise.all(promises)
            .then((callback) => {
                console.log(callback, 'callback');
                
            })
            .catch((err) => alert(err));
           
        },
        async fireUpdate() {
            if(this.validate()) {
                console.log('valid')
                this.isBusy = true
                let th = this;
                let data = {
                    community: this.community,
                    group: this.selectedUsers,
                    user: this.user.data
                }

                let communitiesCollectionRef = null;
                

                communitiesCollectionRef = firebase.firestore()
                            .collection('users')
                            .doc(this.user.data.uid)
                            .collection('communities')

                await communitiesCollectionRef.doc(this.$route.params.id).update({
                    name: this.community.name
                })


                let groupRef =  communitiesCollectionRef.doc(this.$route.params.id).collection('group')
                console.log(groupRef)
                this.saveUsers(groupRef)
                


                let toRemoveUsers = []
                for (let j = 0; j < this.filteredLinkedUsers.length; j++) {
                    let removed = []
                    this.initialFiltered.forEach(user => {
                        if(this.filteredLinkedUsers[j].code != user.code ) {

                        }
                        this.filteredLinkedUsers[j].code != user.code ? removed.push(true) : removed.push(false)
                    });
                    if(removed.includes(false)) {
                        
                    } else {
                        toRemoveUsers.push(this.filteredLinkedUsers[j])
                    }
                }
                console.log('toRemoveUsers', toRemoveUsers)
                for (let m = 0; m < toRemoveUsers.length; m++) {
                    await groupRef.where('uid','==',toRemoveUsers[m].uid).get()
                    .then((snapShot) => {
                        snapShot.forEach((doc) => {
                            doc.ref.delete()
                        });
                        
                        
                    }).catch((error) => {
                        
                    });
            
                }

                this.isBusy = false
                // axios.post('/users/community', data).then(response =>{
                //     console.log(response);
                   
                //     th.isBusy = false;
                //     th.$router.push({path: `/app/communities`})
                    
                // }).catch(e => {
                   
                //     th.isBusy = false;
                    
                // })
            } else {
                 this.$v.community.$touch()
            }
        },
        removeUsers() {
            
            for (let i = 0; i < this.toRemoveUsers.length; i++) {
                for (let k = 0; k < this.selectedUsers.length; k++) {
                   if(this.toRemoveUsers[i].code === this.selectedUsers[k].code) {
                        this.selectedUsers.splice(k,1)
                   }
                    
                }

            }
            this.toRemoveUsers = []

            this.filterAvailableDrivers()
        },
        onSelectToSave(items) {
            this.toRemoveUsers = items

        },
        addUsers() {
            this.selectedUsers = [].concat(this.selectedUsers, this.currentlySelectedUsers);
            
            this.filterAvailableDrivers()
        },
        onSelect(items) {
            this.currentlySelectedUsers = items
        },
        roleSelected() {
            this.filterAvailableDrivers()
        },
        filterAvailableDrivers() {
            this.filteredLinkedUsers = []
            let linkedUsers = []
            let companyDrivers = []
            linkedUsers = this.linkedUsers.filter((user) => {
                let userToSave = user
                let matches = [];
                if(this.selectedUsers.length > 0) {
                    for (let i = 0; i < this.selectedUsers.length; i++) {
                       
                       this.selectedUsers[i].code != user.code ? matches.push(false) : matches.push(true)
                       
                        
                    }

                    // if(matches.includes(true)) {
         
                    // } else {
                        
                    //     return user
                    // }

                    if(!matches.includes(true) && user.role === this.community.role) {
                        let fleetToSave = []
                        if(this.community.role == 'driver') {
                            
                            for (let j = 0; j < userToSave.fleet.length; j++) {
                                
                                if(this.community.vehicleType != null && userToSave.fleet[j].base == this.community.vehicleType) {
                                    fleetToSave.push(userToSave.fleet[j])
                                } 

                            }   
                            
                            user.filteredFleet = fleetToSave
    
                            if(user.filteredFleet.length > 0) {
                                return user
                            }
                        } else {
                            return user
                        }
                    } 

                } else {

                    if(user.role === this.community.role) {
                        let fleetToSave = []
                        if(this.community.role == 'driver') {
                            
                            for (let j = 0; j < userToSave.fleet.length; j++) {
                                
                                if(this.community.vehicleType != null && userToSave.fleet[j].base == this.community.vehicleType) {
                                    fleetToSave.push(userToSave.fleet[j])
                                } 

                            }   
                            
                            user.filteredFleet = fleetToSave
    
                            if(user.filteredFleet.length > 0) {
                                return user
                            }
                        } else {
                            return user
                        }
                    }
                }

            })

            // companyDrivers = this.companyDrivers.filter((driver) => {
            //     let matches = [];
            //     if(this.selectedUsers.length > 0) {
            //         for (let i = 0; i < this.selectedUsers.length; i++) {
                                               
            //            this.selectedUsers[i].code != driver.profiles[0].code ? matches.push(false) : matches.push(true)
            //         }

            //         if(matches.includes(true)) {
         
            //         } else {
            //             let result =  driver.profiles[0]
            //             return result
            //         }
            //     } else {

            //         if(driver.profiles[0].role === this.community.role) {
            //             return driver
            //         }
            //     }
                
            // })

            let filteredInfoCompanyDrivers = []
            for (let i = 0; i < companyDrivers.length; i++) {
                filteredInfoCompanyDrivers.push(companyDrivers[i].profiles[0])
                
            }
            this.filteredLinkedUsers = [].concat(linkedUsers, filteredInfoCompanyDrivers);
            this.searched = this.filteredLinkedUsers
        },
        async getUsers(){
        
            let th = this;
            return new Promise((resolve, reject) => {

                var collection = th.getCollectionPath(this.user)
                collection.onSnapshot((querySnapshot) => {
                    th.linkedUsers = [];
                    querySnapshot.forEach(function(doc) {
                        let dataToSave = doc.data()
                        
                        dataToSave.id = doc.id
                        th.linkedUsers.push(dataToSave)
                    });
                    resolve()
                    
                }, (error) => { 
                    console.log("Error getting documents: ", error);
                    reject()
                    
                });  
                    
            }) 
        },
        async getCompanyDrivers(){
            let th = this ;
            return new Promise((resolve, reject) => {
                let data = {  
                    ownerEmail: this.user.data.email,
                    roleToGet: 'driver' ,
                    ownerUid: this.user.data.uid
                }
                
                
                axios.get('drivers', { params: data }).then(response =>{
                    th.companyDrivers = response.data.users ;
                    resolve()
            
                }).catch(e => {
                    console.log(e);
                    reject()
                
                })
            })
        },
        async getCommunity() {
            let th = this ;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(this.user.data.uid).collection('communities').doc(this.$route.params.id).get()
                .then(function(doc) {
                    if (doc.exists) {
                        let dataToSave = doc.data() ;
                        
                        th.community = dataToSave;
                        th.collectionGroupRef = doc.ref.collection('group')
                       
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                    resolve()
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                    reject()
                });
            })

        },
        async getUserGroup(){
        
            let th = this;
            return new Promise((resolve, reject) => {

                var collection = th.collectionGroupRef
                collection.get().then((querySnapshot) => {
                //collection.onSnapshot((querySnapshot) => {
                    th.selectedUsers = [];
                    
                    querySnapshot.forEach(function(doc) {
                        let dataToSave = doc.data()
                        dataToSave.existant = true;
                        dataToSave.id = doc.id
                        th.selectedUsers.push(dataToSave)
                    });
                    resolve()
                    
                }, (error) => { 
                    console.log("Error getting documents: ", error);
                    reject()
                    
                });  
                    
            }) 
        },
        async obtainViewResources() {
            this.isBusy = true;
            let th = this;
            
            await this.getCommunity()
            await this.getUserGroup()
            if(this.user.data.activeRole === 'company-admin') {

                await this.getCompanyDrivers()
            }
            await this.getVehicleTypes()
            await this.getUsers()
            this.filterAvailableDrivers()
            this.initialFiltered = this.filteredLinkedUsers
            this.isBusy = false;
        },
        getCollectionPath (user) {
            
            return firebase.firestore().collection('users').doc(this.user.data.uid).collection('linked-users')
        },
        
        validate(){
            let name,role;
            name = this.$v.community.name.$invalid;
           
           
            return !name  ? true : false ;


        },
        getValidationClass (fieldName) {
            let field = null;

            
            field = this.$v.community[fieldName];
            
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        
       
       
       
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      dialogDimensions () {
        return {
            //left: this.$parent.$parent.offsetLeft + 'px'
            left: this.$store.state.dialogLeft + 'px' ,
            width: this.$store.state.dialogWidth + 'px'
            //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
        }
      },
       
    },
    watch: {
        '$store.state.sidebarMinimize': function() {
            this.$store.commit('centerDialog',window.innerWidth)
        },
        '$store.state.sidebarShow': function(){
            this.$store.commit('centerDialog',window.innerWidth)
        },
    },
    created () {
        window.addEventListener('resize', this.myEventHandler);
       this.obtainViewResources()
       
    },
    destroyed() {
        
        window.removeEventListener('resize', this.myEventHandler);
        //this.$refs.masterTable.removeEventListener('scroll', this.onTableScrollHorizontal);
    },
    
    validations: {
       
        community: {
            
            name: {
                required
            },
            
                          
        }
    }
}
</script>  